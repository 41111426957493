<template>
  <div class="sticky-banner__content-step-wrapper">
    <div class="sticky-banner__content-title title" v-html="question" />
    <div class="sticky-banner__content-flex flex">
      <div
          class="buttons__col-full"
          v-for="(option, i) in options"
          :key="`edging-option-${i}`"
      >
        <a
            @click.prevent="selectOption(option)"
            href="#"
            class="button active button-power"
            :class="{ active: selectedValue === option.value }"
        >
          <span class="col__text" v-if="option.id === '20V'">YES, <img src="@/assets/20V.svg"/></span>
          <span class="col__text" v-else-if="option.id === '40V'">YES, <img src="@/assets/40V.svg"/></span>
          <span class="col__text" v-else-if="option.id === '20V-40V'">YES, <img src="@/assets/20V.svg"/> & <img src="@/assets/40V.svg"/></span>
          <span class="col__text" v-else v-html="option.title"/>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import FourChoiceStep from '@/components/common/FourChoiceStep'

export default {
  name: 'BatteryType',
  extends: FourChoiceStep,
  data () {
    return {
      question: 'Do you own any HART battery powered tools?',
      options: [
        { id: '20V', title: 'Yes, 20V', value: '20V' },
        { id: '40V', title: 'Yes, 40V', value: '40V' },
        { id: '20V-40V', title: 'Yes, Both 20V & 40V', value: '20V_40V' },
        { id: 'No', title: 'No, not yet', value: 'no' }
      ]
    }
  }
}
</script>
