<template>
  <div class="sticky-banner__content-step-wrapper" :class="{ system: displaySystemSuggestion }">
    <div class="sticky-banner__content-title title">Your Result is:</div>
    <div class="sticky-banner__content-img image">
      <img :src="productImage"/>
    </div>
    <div class="sticky-banner__content-name product-name">{{ result.name }}</div>
    <div class="sticky-banner__content-buttons buttons">
      <div class="buttons__col">
        <a
            :href="buyNowLink"
            class="button small"
            target="_blank"
            data-gtm-id="buy-now-button"
            :data-gtm-location="gtmLocation"
            :data-model-number="modelNumber"
            :data-product-id="prodctUpc"
        >
          <i class="buy-now">&nbsp;</i>
          Buy Now
        </a>
      </div>
      <div class="buttons__col">
        <a
            :href="learnMoreLink"
            class="button small"
            target="_blank"
            data-gtm-id="learn-more-button"
            :data-gtm-location="gtmLocation"
            :data-model-number="modelNumber"
        >
          Learn More
        </a>
      </div>
    </div>
    <div class="buttons__col-full" v-if="displaySystemSuggestion">
      <a
          href="#"
          class="button active"
          @click.prevent="suggestionClicked"
      >
        Want to stay in the
        <img src="@/assets/20V-system.svg" v-if="suggestionBattery === '20V'" />
        <img src="@/assets/40V-system.svg" v-if="suggestionBattery === '40V'" />
        ?
      </a>
    </div>
    <div class="start-over">
      <a
        href="#"
        data-gtm-id="quiz-button"
        :data-gtm-location="gtmLocation"
        data-gtm-cta="start over"
        @click.prevent="startOver"
      >
        Start Over
      </a>
    </div>
  </div>
</template>
<script>
import { withRouter } from '@/lib/mixins/core'

const req = require.context(`@/assets/`, false, /^\.\/.*\.png$/)

export default {
  name: 'Result',
  mixins: [withRouter],
  props: {
    result: {
      type: Object,
      required: true
    },
    suggestion: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    displaySystemSuggestion () {
      return this.suggestion && Object.keys(this.suggestion).length > 0
    },
    suggestionBattery () {
      return this.displaySystemSuggestion ? this.suggestion.battery : ''
    },
    productImage () {
      return req(`./${this.result.image}`)
    },
    productUpc () {
      return this.result.upc
    },
    buyNowLink () {
      return this.result.buyNowLink
    },
    learnMoreLink () {
      return this.result.learnMoreLink
    },
    modelNumber() {
      return this.result.image.slice(0, -4)
    },
    category() {
      return this.$store.getters['app/category'].id
    },
    gtmLocation() {
      return `https://www.harttools.com/outdoorquiz/${this.category}`
    }
  },
  methods: {
    suggestionClicked () {
      if (!this.displaySystemSuggestion) {
        return
      }

      this.$emit('update-product', this.suggestion.product)
    },
    startOver () {
      this.setRoute('/')
    }
  }
}
</script>
