<template>
  <div class="sticky-banner__content-step-wrapper">
    <div class="sticky-banner__content-title title">{{ question }}</div>
    <div class="sticky-banner__content-flex flex">
      <div class="flex-column">
        <div class="flex-text">
          <div class="content-img">
            <img :src="icon"/>
          </div>
          <div class="column-content">
            <div class="sticky-banner__content-range range">
              <slider v-model="selectedValue"/>
              <span class="range__text">{{ rangeText }}</span>
            </div>
            <div class="sticky-banner__content-button">
              <a
                  href="#"
                  class="button"
                  @click.prevent="nextStep"
                  :class="{ active: selectedValue > 0 }"
                  v-html="buttonText"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Slider from '@/components/common/Slider'

export default {
  name: 'SelectYardSize',
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  components: { Slider },
  computed: {
    icon () {
      return ''
    },
    buttonText () {
      return ''
    }
  },
  mounted () {
    this.selectedValue = this.value
  },
  methods: {
    nextStep () {
      if (this.selectedValue > 0) {
        this.$emit('next-step')
      }
    }
  },
  watch: {
    selectedValue (value) {
      this.$emit('input', value)
    }
  },
  data () {
    return {
      question: '',
      rangeText: 'Slide and click Button to Select',
      selectedValue: 0
    }
  }
}
</script>
