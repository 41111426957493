<template>
  <div class="sticky-banner__content-step-wrapper">
    <div class="sticky-banner__content-title title" v-html="question" />
    <div class="sticky-banner__content-flex flex">
      <div
          class="buttons__col-full"
          v-for="(option, i) in options"
          :key="`edging-option-${i}`"
      >
        <a
            @click.prevent="selectOption(option)"
            href="#"
            class="button active button-power"
            :class="{ active: selectedValue === option.value }"
        >
          <span class="col__text" v-html="option.title"/>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TwoChoiceStep',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    selectOption (option) {
      this.selectedValue = option.value
      this.$emit('input', this.selectedValue)
      this.$emit('next-step')
    }
  },
  data () {
    return {
      selectedValue: '',
      options: []
    }
  }
}
</script>
