import routes, { NotFoundRoutePath } from '@/router'
import settings from '@/lib/settings'

const state = () => ({
  category: '',
  route: '/'
})

const getters = {
  route (state) {
    return routes.find(route => route.path === state.route)
  },

  category (state) {
    return settings.categories.find(category => category.id === state.category)
  }
}

const mutations = {
  SET_ROUTE (state, path) {
    const routeExists = routes.find(route => route.path === path)
    if (routeExists) {
      state.route = path
    } else {
      state.route = NotFoundRoutePath
    }
  },

  SET_CATEGORY (state, categoryId) {
    state.category = categoryId
  }
}

const actions = {
  setRoute ({ commit }, path) {
    commit('SET_ROUTE', path)
  },

  setCategory ({ commit }, categoryId) {
    commit('SET_CATEGORY', categoryId)
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
