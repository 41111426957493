import Vue from 'vue'
import App from './App.vue'
import router from './router'
import createStore from './store'
import settings from '@/lib/settings'
import './assets/styles/_shared.scss'
import 'vue-slider-component/theme/antd.css'

Vue.config.productionTip = false

settings.categories.forEach((category) => {
  const selector = `[data-hart-quiz][data-category="${category.id}"]`
  const store = createStore()

  if (!document.querySelector(selector)) {
    return
  }

  // App.store = store
  App.router = router

  new Vue({
    router,
    store,
    render: h => h(App, {
      props: {
        category: category.id
      }
    })
  }).$mount(selector)
})
