<template>
  <div class="sticky-banner">
    <div class="sticky-banner__content content">
      <component
          v-if="!result"
          :is="currentStep.component"
          v-model="stepValue"
          @next-step="nextStep"
      />
      <pagination
          v-if="!result"
          :steps="steps"
          :active-step="stepNumber + 1"
      />
      <result
          v-if="result"
          :result="result"
          :suggestion="suggestion"
          @update-product="updateProduct"
      />
    </div>
  </div>
</template>
<script>
import Pagination from '@/components/common/Pagination'
import Result from '@/components/Result'
import {findProductBySku} from '@/lib/mixins/pick-result'

export default {
  components: { Result, Pagination },
  methods: {
    updateProduct (sku) {
      if (!sku) {
        return
      }

      const product = findProductBySku(sku)
      if (product) {
        this.result = product
        this.suggestion = null
      }
    }
  },
  data () {
    return {
      result: null,
      suggestion: null,
      steps: 5
    }
  }
}
</script>
