<template>
  <div class="sticky-banner">
    <div class="sticky-banner__content">
      <div class="sticky-banner__content-text sticky-banner__content-text-error">
        <div>
          <h2>Not Found!</h2>
          <p>Given location hasn't been found.</p>
          <p>
            <button
                @click="returnBack"
                class="sticky-banner__btn button small"
            >Return Back
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { withRouter } from '@/lib/mixins/core'

export default {
  name: 'NotFound',
  mixins: [withRouter],
  methods: {
    returnBack () {
      this.setRoute('/')
    }
  }
}
</script>
