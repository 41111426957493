<script>
import TwoChoiceStep from '@/components/common/TwoChoiceStep'

export default {
  name: 'Importance',
  extends: TwoChoiceStep,
  data () {
    return {
      question: 'What is more important to you?',
      options: [
        { title: 'Saving time', cssClass: 'icon_3', value: 'savingTime' },
        { title: 'Saving money', cssClass: 'icon_4', value: 'savingMoney' }
      ]
    }
  }
}
</script>
