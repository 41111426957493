<script>
import TwoChoiceStep from '@/components/common/TwoChoiceStep'

export default {
  name: 'DescribesYou',
  extends: TwoChoiceStep,
  data () {
    return {
      question: 'Which best describes you?',
      options: [
        { title: 'Give me the best string trimmer there is!', cssClass: 'icon_5', value: 'best' },
        { title: 'I like to save money but still get the job done!', cssClass: 'icon_4', value: 'saveMoney' }
      ]
    }
  }
}
</script>
