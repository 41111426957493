<script>
import TwoChoiceStep from '@/components/common/TwoChoiceStep'

export default {
  name: 'DescribesYou',
  extends: TwoChoiceStep,
  data () {
    return {
      question: 'What type of cutting are you looking to do with your saw?',
      options: [
        { title: 'Light pruning and trimming', cssClass: 'icon_10', value: 'pruningAndTrimming' },
        { title: 'Light trimming and limbing', cssClass: 'icon_11', value: 'trimmingAndLimbing' }
      ]
    }
  }
}
</script>
