<script>
import TwoChoiceStep from '@/components/common/TwoChoiceStep'

export default {
  name: 'PowerType',
  extends: TwoChoiceStep,
  data () {
    return {
      question: 'What is more important?',
      options: [
        { title: 'Gas-like<br />Power', cssClass: 'icon_1', value: 'gas' },
        { title: 'Lightweight & Compact', cssClass: 'icon_2', value: 'lightweight' }
      ]
    }
  }
}
</script>
