import BatteryType from '@/components/BatteryType'
import SelectYardSize from '../trimmers/SelectYardSize'
import Hilly from './Hilly'

const steps = {
  batteryType: {
    component: BatteryType,
    bind: 'batteryType',
    next: 'materialType'
  },
  materialType: {
    component: SelectYardSize,
    bind: 'size',
    next: (data) => data.size !== 3 ? 'hilly' : null
  },
  hilly: {
    component: Hilly,
    bind: 'hilly'
  }
}

export default steps
