import Vue from 'vue'
import Vuex from 'vuex'
import app from '@/store/modules/app'

Vue.use(Vuex)

export default function createStore() {
  return new Vuex.Store({
    modules: {
      app
    },
    strict: process.env.NODE_ENV !== 'production'
  })
}
