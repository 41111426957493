<script>
import SliderStep from '@/components/common/SliderStep'

export default {
  name: 'SelectYardSize',
  extends: SliderStep,
  computed: {
    icon () {
      return require(`@/assets/q1-trimmer-${this.selectedValue + 1}.svg`)
    },
    buttonText () {
      const labels = [
        'Use slider to show yard size',
        '1/4 acre (2 basketball courts) or less',
        '1/4 acre - 1/2 acre (half of a football field)',
        '1/2 acre (half of a football field) or above'
      ]

      return labels[this.selectedValue]
    }
  },
  data () {
    return {
      question: 'What size yard do you have?'
    }
  }
}
</script>
