<script>
import TwoChoiceStep from '@/components/common/TwoChoiceStep'

export default {
  name: 'DescribesYou',
  extends: TwoChoiceStep,
  data () {
    return {
      question: 'Which statement below best describes you?',
      options: [
        { title: 'Give me the best cordless saw there is!', cssClass: 'icon_12', value: 'best' },
        { title: 'I like to save a little money and still be able to get the job done', cssClass: 'icon_4', value: 'saveMoney' }
      ]
    }
  }
}
</script>
