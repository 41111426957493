<template>
  <div class="sticky-banner">
    <div class="sticky-banner__content">
      <div class="sticky-banner__content-text sticky-banner__content-text-error">
        <div>
          <h2>Configuration Error!</h2>
          <p>Category is not defined or is wrong. Please, check widget's settings.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryError'
}
</script>
