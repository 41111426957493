import settings from '@/lib/settings'

/**
 * Check whether the rule matches data collected from user.
 * @param instance
 * @param rule
 * @returns {boolean}
 */
const isRuleMatches = (instance, rule) => {
  let matching = 0
  const keys = Object.keys(rule)

  keys.forEach(field => {
    if (field in instance && instance[field] === rule[field] || field === 'suggestion') {
      matching++
    }
  })

  return matching === keys.length
}

/**
 * Pick result for given category.
 * @param instance
 * @param category
 * @param batteryType
 */
const pickResult = (instance, category, batteryType) => {
  const products = settings.products[category]
  if (!products) {
    return null
  }

  for (let id in products) {
    const product = products[id]
    if (!product.rules) {
      continue
    }

    for (let rule of product.rules) {
      if (isRuleMatches(instance, rule)) {
        const suggestion = rule.suggestion && batteryType === rule.suggestion.battery ? rule.suggestion : null
        return { product, suggestion }
      }
    }
  }

  return null
}

/**
 * Find a product by its SKU number.
 * @param sku
 * @returns {null|*}
 */
const findProductBySku = (sku) => {
  for (let category in settings.products) {
    const products = settings.products[category]
    if (!products) {
      continue
    }

    for (let product in products) {
      if (product === sku) {
        return products[product]
      }
    }
  }

  return null
}

export { pickResult, findProductBySku }
