<template>
  <vue-slider
      v-bind="options"
      v-model="sliderValue"
  />
</template>
<script>
import VueSlider from 'vue-slider-component'

export default {
  name: 'Slider',
  components: { VueSlider },
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 3
    },
    value: {
      type: Number,
      default: 0
    }
  },
  watch: {
    sliderValue (value) {
      this.$emit('input', value)
    }
  },
  mounted () {
    this.sliderValue = this.value
  },
  data () {
    return {
      sliderValue: 0,
      options: {
        min: this.min,
        max: this.max,
        tooltip: 'none',
        dotSize: 18
      }
    }
  }
}
</script>
