<script>
import TwoChoiceStep from '@/components/common/TwoChoiceStep'

export default {
  name: 'Hilly',
  extends: TwoChoiceStep,
  data () {
    return {
      question: 'Is your yard hilly?',
      options: [
        { title: 'Yes', cssClass: 'icon_8', value: 'yes' },
        { title: 'No', cssClass: 'icon_9', value: 'no' }
      ]
    }
  }
}
</script>
