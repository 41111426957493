<script>
import { withMultiSteps } from '@/lib/mixins/multi-steps'
import { pickResult } from '@/lib/mixins/pick-result'
import steps from '@/views/trimmers/steps'
import StepsContainer from '@/components/common/StepsContainer'

export default {
  name: 'Trimmers',
  mixins: [withMultiSteps(steps)],
  extends: StepsContainer,
  methods: {
    pickResult () {
      const {product, suggestion} = pickResult(this, 'trimmers', this.batteryType)
      this.result = product
      this.suggestion = suggestion
    }
  },
  data () {
    return {
      batteryType: '',
      size: 0,
      powerType: '',
      edging: '',
      describes: ''
    }
  }
}
</script>
