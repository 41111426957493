<script>
import SliderStep from '@/components/common/SliderStep'

export default {
  name: 'MaterialType',
  extends: SliderStep,
  computed: {
    icon () {
      return require(`@/assets/q1-blower-${this.selectedValue + 1}.svg`)
    },
    buttonText () {
      const labels = [
        'Use slider to increase power',
        'Lightweight dry debris on a hard surface',
        'Dry debris on a hard surface and dry debris in the yard',
        'Dry debris on a hard surface, dry debris in the yard, and wet leaves in the yard'
      ]

      return labels[this.selectedValue]
    }
  },
  data () {
    return {
      question: 'What type of material are you trying to move?'
    }
  }
}
</script>
