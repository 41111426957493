import { mapActions, mapGetters } from 'vuex'

const withRouter = {
  methods: {
    ...mapActions('app', ['setRoute']),
  }
}

const withCategory = {
  computed: {
    ...mapGetters('app', ['category'])
  },
}

export { withRouter, withCategory }
