const withMultiSteps = (steps) => ({
  data () {
    return {
      step: '',
      stepNumber: 0
    }
  },
  methods: {
    pickResult () {
    },
    nextStep () {
      if (!this.currentStep.next) {
        this.pickResult()
        return
      }

      if (typeof this.currentStep.next === 'string') {
        this.step = this.currentStep.next
      } else if (typeof this.currentStep.next === 'function') {
        const nextStep = this.currentStep.next(this)
        if (!nextStep) {
          this.pickResult()
          return
        }

        this.step = nextStep
      }
      this.stepNumber += 1
    }
  },
  computed: {
    currentStep () {
      if (!this.step) {
        return steps[Object.keys(steps)[0]]
      }

      for (const step in steps) {
        if (step === this.step) {
          return steps[step]
        }
      }
      return {}
    },
    stepValue: {
      get () {
        return this[this.currentStep.bind]
      },
      set (value) {
        this.$set(this, this.currentStep.bind, value)
      }
    }
  }
})

export { withMultiSteps }
