<script>
import { withMultiSteps } from '@/lib/mixins/multi-steps'
import { pickResult } from '@/lib/mixins/pick-result'
import steps from '@/views/chainsaws/steps'
import StepsContainer from '@/components/common/StepsContainer'

export default {
  name: 'Chainsaws',
  mixins: [withMultiSteps(steps)],
  extends: StepsContainer,
  methods: {
    pickResult () {
      const {product, suggestion} = pickResult(this, 'chainsaws', this.batteryType)
      this.result = product
      this.suggestion = suggestion
    }
  },
  data () {
    return {
      batteryType: '',
      size: 0,
      steps: 3,
      powerType: '',
      describesYou: '',
      cuttingType: ''
    }
  }
}
</script>
