import BatteryType from '@/components/BatteryType'
import SelectYardSize from '@/views/trimmers/SelectYardSize'
import PowerType from '@/views/trimmers/PowerType'
import Edging from '@/views/trimmers/Edging'
import DescribesYou from '@/views/trimmers/DescribesYou'

const steps = {
  batteryType: {
    component: BatteryType,
    bind: 'batteryType',
    next: 'size'
  },
  size: {
    component: SelectYardSize,
    bind: 'size',
    next: 'powerType'
  },
  powerType: {
    component: PowerType,
    bind: 'powerType',
    next (data) {
      if (data.size === 3) {
        return data.powerType === 'gas' ? 'describes' : null
      }
      return 'edging'
    }
  },
  edging: {
    component: Edging,
    bind: 'edging',
    next: ''
  },
  describes: {
    component: DescribesYou,
    bind: 'describes'
  }
}

export default steps
