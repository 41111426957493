import { mapGetters } from 'vuex'
import settings from '@/lib/settings'

const req = require.context(`@/assets/`, false, /^\.\/.*\.png$/)

const preloadImage = (image) => {
  const imageEl = new Image()
  imageEl.src = req(`./${image}`)
}

const withImagePreloader = {
  computed: {
    ...mapGetters('app', {
      currentCategory: 'category'
    })
  },
  methods: {
    preloadCategoryImages () {
      const products = Object.values(settings.products[this.currentCategory.id])
      if (!products || !products.length) {
        return
      }

      products.forEach(product => {
        preloadImage(product.image)
      })
    }
  }
}

export { withImagePreloader }
