<template>
  <component :is="route.component"></component>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'StateRouterView',
  computed: {
    ...mapGetters('app', ['route'])
  }
}
</script>
