import BatteryType from '@/components/BatteryType'
import PowerType from './PowerType'
import DescribesYou from './DescribesYou'
import CuttingType from './CuttingType'

const steps = {
  batteryType: {
    component: BatteryType,
    bind: 'batteryType',
    next: 'powerType'
  },
  powerType: {
    component: PowerType,
    bind: 'powerType',
    next: (data) => data.powerType === 'gas' ? 'describesYou' : 'cuttingType'
  },
  describesYou: {
    component: DescribesYou,
    bind: 'describesYou'
  },
  cuttingType: {
    component: CuttingType,
    bind: 'cuttingType'
  }
}

export default steps
