<script>
import { withMultiSteps } from '@/lib/mixins/multi-steps'
import { pickResult } from '@/lib/mixins/pick-result'
import steps from '@/views/blowers/steps'
import StepsContainer from '@/components/common/StepsContainer'

export default {
  name: 'Blowers',
  mixins: [withMultiSteps(steps)],
  extends: StepsContainer,
  methods: {
    pickResult () {
      const {product, suggestion} = pickResult(this, 'blowers', this.batteryType)
      this.result = product
      this.suggestion = suggestion
    }
  },
  data () {
    return {
      batteryType: '',
      materialType: 0,
      importance: '',
      powerType: ''
    }
  }
}
</script>
