<template>
  <state-router-view class="hart-quiz" />
</template>
<script>
import StateRouterView from './components/StateRouterView'
import { getCategoryById } from '@/lib/categories'
import { mapActions } from 'vuex'
import { withImagePreloader } from './lib/preloader'

export default {
  name: 'App',
  mixins: [withImagePreloader],
  props: {
    category: {
      type: String,
      default: ''
    }
  },
  components: {
    StateRouterView
  },
  mounted () {
    this.initCategory()
  },
  methods: {
    ...mapActions('app', ['setCategory']),
    initCategory () {
      const category = getCategoryById(this.category)
      if (!category) {
        this.$setRoute('/category-error')
      } else {
        this.setCategory(category.id)
        this.preloadCategoryImages()
      }
    }
  }
}
</script>
