<template>
  <div class="sticky-banner">
    <img class="sticky-banner__img" src="@/assets/main-bg.png" />
    <a href="./" class="sticky-banner__btn btn-start" @click.prevent="startQuiz"
      >Take our Quiz</a
    >
  </div>
</template>

<script>
import { withCategory, withRouter } from "@/lib/mixins/core";

export default {
  name: "Intro",
  mixins: [withRouter, withCategory],
  methods: {
    startQuiz() {
      this.setRoute(this.category.path);
    },
  },
};
</script>
