<script>
import TwoChoiceStep from '@/components/common/TwoChoiceStep'

export default {
  name: 'Edging',
  extends: TwoChoiceStep,
  data () {
    return {
      question: 'Is edging a driveway/sidewalk a must?',
      options: [
        { title: 'Yes', cssClass: 'icon_6', value: 'yes' },
        { title: 'No', cssClass: 'icon_7', value: 'no' }
      ]
    }
  }
}
</script>
