import settings from '@/lib/settings'

/**
 * Find category by ID.
 * @param id
 * @returns {*}
 */
const getCategoryById = id => {
  return settings.categories.find(category => category.id === id)
}

export { getCategoryById }
