import Intro from '@/views/Intro.vue'
import CategoryError from '@/views/CategoryError'
import NotFound from '@/views/NotFound'
import Trimmers from '@/views/trimmers/Trimmers'
import Blowers from '@/views/blowers/Blowers'
import Mowers from '@/views/mowers/Mowers'
import Chainsaws from '@/views/chainsaws/Chainsaws'

const NotFoundRoutePath = '/not-found'

const routes = [
  {
    path: '/',
    component: Intro
  },
  {
    path: '/trimmers',
    component: Trimmers
  },
  {
    path: '/blowers',
    component: Blowers
  },
  {
    path: '/mowers',
    component: Mowers
  },
  {
    path: '/chainsaws',
    component: Chainsaws
  },
  {
    path: '/category-error',
    component: CategoryError
  },
  {
    path: NotFoundRoutePath,
    component: NotFound
  }
]

export { NotFoundRoutePath }
export default routes
