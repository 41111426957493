import BatteryType from '@/components/BatteryType'
import MaterialType from './MaterialType'
import Importance from './Importance'
import PowerType from './PowerType'

const steps = {
  batteryType: {
    component: BatteryType,
    bind: 'batteryType',
    next: 'materialType'
  },
  materialType: {
    component: MaterialType,
    bind: 'materialType',
    next (data) {
      const nextSteps = [
        'importance',
        'powerType',
        null
      ]
      return nextSteps[data.materialType - 1]
    }
  },
  importance: {
    component: Importance,
    bind: 'importance'
  },
  powerType: {
    component: PowerType,
    bind: 'powerType'
  }
}

export default steps
