<template>
  <div class="sticky-banner__content-pagination pagination">
    <a
        v-for="step in steps"
        :key="`step-${step}`"
        :class="{ active: step === activeStep }"
        @click.prevent="stepClick(step)"
        href="#"
    >&nbsp;</a>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    steps: {
      type: Number,
      default: 4
    },
    activeStep: {
      type: Number,
      default: 1
    }
  },
  methods: {
    stepClick (stepNumber) {
      this.$emit('step-click', stepNumber)
    }
  }
}
</script>
